/* Define Futura Futuris Regular */

/* Define FuturaFuturisC as Light */
@font-face {
  font-family: 'FuturaFuturis';
  src: url('./fonts/futuraFuturisC/FuturaFuturisC.eot'); /* IE9 Compat Modes */
  src: url('./fonts/futuraFuturisC/FuturaFuturisC.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('./fonts/futuraFuturisC/FuturaFuturisC.woff2') format('woff2'), /* Super Modern Browsers */
      url('./fonts/futuraFuturisC/FuturaFuturisC.woff') format('woff'), /* Modern Browsers */
      url('./fonts/futuraFuturisC/FuturaFuturisC.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: 300; /* Light */
  font-style: normal;
}

/* Define FuturaFuturis Bold as Normal */
@font-face {
  font-family: 'FuturaFuturis';
  src: url('./fonts/futuraFuturisCbold/FuturaFuturisC-Bold.eot'); /* IE9 Compat Modes */
  src: url('./fonts/futuraFuturisCbold/FuturaFuturisC-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('./fonts/futuraFuturisCbold/FuturaFuturisC-Bold.woff2') format('woff2'), /* Super Modern Browsers */
      url('./fonts/futuraFuturisCbold/FuturaFuturisC-Bold.woff') format('woff'), /* Modern Browsers */
      url('./fonts/futuraFuturisCbold/FuturaFuturisC-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: 400; /* Normal */
  font-style: normal;
}

/* Define FuturaFuturis Black as Bold */
@font-face {
  font-family: 'FuturaFuturis';
  src: url('./fonts/futuraFuturisBlackC/FuturaFuturisBlackC.eot'); /* IE9 Compat Modes */
  src: url('./fonts/futuraFuturisBlackC/FuturaFuturisBlackC.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('./fonts/futuraFuturisBlackC/FuturaFuturisBlackC.woff2') format('woff2'), /* Super Modern Browsers */
      url('./fonts/futuraFuturisBlackC/FuturaFuturisBlackC.woff') format('woff'), /* Modern Browsers */
      url('./fonts/futuraFuturisBlackC/FuturaFuturisBlackC.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: 700; /* Bold */
  font-style: normal;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: 'FuturaFuturis', Helvetica, sans-serif;
  background-color: hsl(0, 0%, 91%);
}

/* Main container styles */
.App {
  min-height: 100vh;
  min-height: 100svh;
  font-size: 0.95rem;
  font-size: clamp(0.1rem, 0.95rem, 2rem);
}

.error-message {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  color: hsl(0, 0%, 60%);
  max-width: 40vh;
}

.error-message2 {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  color: hsl(0, 0%, 60%);
  max-width: 40vh;
  color: hsl(347, 100%, 54%);
}

.header {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 4em;
  justify-content: center;
  max-width: 50vh;
  min-width: 10em;
}

.header img {
  min-width: 10%;
  max-width: 98%;
  align-self: center;
}

.description-container {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  margin-bottom: 0.3em;
}

/* Description styles */
.description-text-first-line {
  text-align: center;
  font-size: 1.6vh;
  color: hsl(0, 0%, 23%);
  /*line-height: 1.25;*/
  font-weight: 400;
}

.description-text-rest-lines {
  text-align: center;
  font-size: 1.2vh;
  color: hsl(0, 0%, 23%);
  /*line-height: 1.25;*/
  font-weight: 400;
}

.description-text-rest-lines3 {
  text-align: center;
  font-size: 1.2vh;
  color: hsl(0, 0%, 23%);
  /*line-height: 1.25;*/
  font-weight: 800;
}

.description-text-rest-lines2 {
  text-align: center;
  font-size: 1.6vh;
  color: hsl(347, 100%, 54%);
  /*line-height: 1.25;*/
  font-weight: 400;
}

.container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /*height: 49vh;*/
}

.container {
  background-color: hsl(0, 0%, 100%);
  border-radius: 28px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 40vh;
  display: flex;
  flex-direction: column;
  height: 50vh;
  /* max-height: 50vh; */
}

.create-square {
  background-color: hsl(0, 0%, 97%);
  display: flex;
  justify-content: center;
  margin-left: 2.7vh;
  margin-right: 2.7vh;
  margin-top: 3.4vh;
  height: 75%;
  flex-direction: column;
}

.scaled-gif {
  /* width: 100%; */
  max-width: 100%;
  max-height: 100%;
  /* height: 70%; */
  object-fit: contain;
  object-position: center;
}

.create-btn {
  background-color: hsl(347, 100%, 54%);
  color: white;
  border: none;
  border-radius: 4vh;
  width: 26vh;
  height: 8vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  align-self: center;
  justify-self: center;
  cursor: pointer;
  font-size: 1.8vh;
  font-weight: 800;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

@keyframes scaleDot {
  0%, 80%, 100% {
    transform: scale(1);
    opacity: 0.5;
  }
  40% {
    transform: scale(1.5);
    opacity: 1;
  }
}

@keyframes colorChange {
  0%, 20%, 100% {
    fill: #000; /* Default color */
  }
}

.generating-text-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 7vh;
}

.dot {
  animation: scaleDot 1.4s infinite ease-in-out both, colorChange 1.4s infinite ease-in-out both;
  transform-origin: center bottom;
  margin: 0 5px;
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}

.dot:nth-child(3) {
  animation-delay: 0s;
}

.download-btn, .upload-btn {
  border: 0.33vh solid hsl(127, 77%, 51%);
  border-radius: 1.4vh;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1.9vh;
  font-size: 1.6vh;
  font-weight: 500;
  height: 3.7vh;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.create-btn:focus, .create-btn:active,
.download-btn:focus, .download-btn:active {
  outline: none; /* Ensure no outline is shown when focused or active */
  box-shadow: none; /* Remove any default focus shadow */
  -webkit-tap-highlight-color: transparent; /* Remove the blue highlight on mobile devices */
}

.download-btn {
  color: hsl(151, 100%, 35%);
  width: 50%;
  height: 8%;
  margin-top: 6%;
  align-self: center;
  gap: 0.7vh;
  user-select: none;
}

.upload-btn {
  grid-area: upload-btn;
  border-color: hsl(184, 100%, 50%);
  border-radius: 50px;
  color: hsl(184, 100%, 42%);
  margin-left: 5%;
  margin-right: 16%;
  gap: 0.7vh;
}

.download-btn-text {
  color: hsl(151, 100%, 35%);
  user-select: none;
}

.svg-arrow {
  height: 48%;
  width: auto;
  fill: hsl(151, 100%, 35%);
  transition: transform 0.3s ease-in-out;
}

.svg-arrow.celebrate {
  animation: celebrate-animation 0.7s ease-in-out;
}

@keyframes celebrate-animation {
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
}

.upload-btn-text {
  color: hsl(184, 100%, 42%);
  user-select: none;
}

/* Heart checkbox */

.heart-container {
  --heart-color: hsl(347, 100%, 54%);
  --heart-color-grey: hsl(184, 100%, 42%);
  position: relative;
  width: 2vh;
  height: 2vh;
  transition: .3s;
}

.heart-container .checkbox {
  position: absolute;
  width: 3vh;
  height: 3vh;
  opacity: 0;
  z-index: 20;
  cursor: pointer;
}

.heart-container .checkbox:focus,
.heart-container .checkbox:active {
    outline: none; /* Ensure no outline is shown when focused or active */
    box-shadow: none; /* Remove any default focus shadow */
    -webkit-tap-highlight-color: transparent; /* Remove the blue highlight on mobile devices */
}

.heart-container .svg-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heart-container .svg-outline,
.heart-container .svg-filled,
.heart-container .svg-grey {
  fill: var(--heart-color);
  position: absolute;
}

.heart-container .svg-filled,
.heart-container .svg-grey {
  animation: keyframes-svg-filled 1s;
  display: none;
}

.heart-container .svg-celebrate {
  position: absolute;
  animation: keyframes-svg-celebrate .5s;
  animation-fill-mode: forwards;
  display: none;
  stroke: var(--heart-color);
  fill: var(--heart-color);
  stroke-width: 2px;
}

.heart-container .checkbox:checked ~ .svg-container .svg-filled {
  display: block;
}

.heart-container .checkbox:checked ~ .svg-container .svg-celebrate {
  display: block;
}

.heart-container .checkbox:not(:checked) ~ .svg-container .svg-grey {
  display: block;
  fill: var(--heart-color-grey);
  animation: keyframes-svg-grey .5s;
}

@keyframes keyframes-svg-filled {
  0% {
      transform: scale(0);
  }

  25% {
      transform: scale(1.2);
  }

  50% {
      transform: scale(1);
      filter: brightness(1.5);
  }
}

@keyframes keyframes-svg-grey {
  0% {
      transform: scale(1.4);
      opacity: 0;
  }

  50% {
      opacity: 1;
      filter: brightness(1.5);
  }

  100% {
      transform: scale(1);
      opacity: 1;
  }
}

@keyframes keyframes-svg-celebrate {
  0% {
      transform: scale(0);
  }

  50% {
      opacity: 1;
      filter: brightness(1.5);
  }

  100% {
      transform: scale(1.4);
      opacity: 0;
      display: none;
  }
}

/* Scrollable bar styles */
.scroller {
  max-width: 600px;
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  overflow: hidden;
}

.scroller::before,
.scroller::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px;
  pointer-events: none;
  z-index: 1;
}

.scroller::before {
  left: 0;
  background: linear-gradient(to right, var(--clr-primary-800), transparent);
}

.scroller::after {
  right: 0;
  background: linear-gradient(to left, var(--clr-primary-800), transparent);
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 74, 61, 0.514) transparent;
}

.scroller__inner::-webkit-scrollbar {
  height: 5px;
}

.scroller__inner::-webkit-scrollbar-track {
  background: transparent;
}

.scroller__inner::-webkit-scrollbar-thumb {
  background: rgba(212, 81, 81, 0.8);
  border-radius: 10px;
  border: 2px solid transparent;
}

.scroller__inner img {
  max-height: 200px;
  height: auto;
  user-select: none;
}

.scroll-button {
  cursor: pointer;
  font-size: 3em;
  user-select: none;
  margin: 0 10px;
  color: #ff3d3d;
  background: none;
  border: none;
  padding: 0;
}

@media (max-width: 768px) {
  .actions {
      flex-direction: column;
  }
  .actions div {
      margin: 5px 0;
  }
}

/* Animation keyframes */
@keyframes shrinkDisappear {
  0% {
      transform: scale(1);
      opacity: 1;
  }
  100% {
      transform: scale(0);
      opacity: 0;
  }
}

@keyframes scaleAppear {
  0% {
      transform: scale(0);
      opacity: 0;
  }
  100% {
      transform: scale(1);
      opacity: 1;
  }
}

/* Classes to apply the animations */
.shrink-disappear {
  animation: shrinkDisappear 2s forwards;
}

.scale-appear {
  animation: scaleAppear 2s forwards;
}
